import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom';
import { useForm } from '../hooks/useForm';
import { useResetPasswordFetch } from '../hooks/useResetPasswordFetch';
import validator from 'validator';

export async function loader({ params }) {
  return params.token;
}

export const PasswordReset = () => {

  const token = useLoaderData();

  const [fetchStatus, setFetchStatus] = useState(false);

  const initialForm = {
    password: '',
    password_confirmation: ''
  }
  
  const [ formValues, handleInputChange, reset ] = useForm(initialForm);
  
  const {password, password_confirmation} = formValues;

  const { loading, data, error, error_msg } = useResetPasswordFetch('/passwordReset/reset-password', token, 'PUT', { password: password }, fetchStatus);

  const [validPasswordMsgForm, setValidPasswordMsgForm] = useState(false);
  const [equalPasswordMsgForm, setEqualPasswordMsgForm] = useState(false);

  const handleInputPasswordValidation = () => {
    if(validator.isStrongPassword(password)){
      setValidPasswordMsgForm(false);
    }else{
      setValidPasswordMsgForm(true);
    }
  }

  const handleInputPasswordConfirmationValidation = () => {
    if(validator.isStrongPassword(password_confirmation)){
      setValidPasswordMsgForm(false);
      if(validator.equals(password, password_confirmation)){
        setEqualPasswordMsgForm(false);
      }else{
        setEqualPasswordMsgForm(true);
      }
    }else{
      setValidPasswordMsgForm(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //Validación de formulario
    if(isFormValid()){
      setValidPasswordMsgForm(false);
      if(validator.equals(password, password_confirmation)){
        setEqualPasswordMsgForm(false);
        setFetchStatus(true);
      }else{
        setEqualPasswordMsgForm(true);
      }
    }else{
      setValidPasswordMsgForm(true);
    }
  }

  const isFormValid = () => {
    if(!validator.isStrongPassword(password)){
        return false;
    }else if(!validator.isStrongPassword(password_confirmation)){
        return false;
    }
    return true;
  }

  return (
    <>
      {!fetchStatus 
        ? (
          <div className="main-container">
            <div className="allergien-index-main-container">
              <div className="allergien-index-sub-container">
                <div className='mb-5'>
                  <h2 className='text-center'>Recuparación de contraseña</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label>Contraseña</label>
                    <input
                      className='form-control'
                      name='password'
                      onBlur={handleInputPasswordValidation}
                      onChange={handleInputChange}
                      placeholder='Contraseña'
                      type='password'
                      value={password}
                      ></input>
                  </div>
                  <div className='mb-3'>
                    <label>Confirmación</label>
                    <input
                      className='form-control'
                      name='password_confirmation'
                      onBlur={handleInputPasswordConfirmationValidation}
                      onChange={handleInputChange}
                      placeholder='Confimación de contraseña'
                      type='password'
                      value={password_confirmation}
                    ></input>
                  </div>
                  {validPasswordMsgForm
                    && (
                    <div className='mb-3'>
                      <div className='alert alert-danger text-center'>
                        <div><b>Contraseña no valida</b></div>
                        <div>Debe contener al menos:</div>
                        <div>• 8 caracteres</div>
                        <div>• 1 mayúscula</div>
                        <div>• 1 minúscula</div>
                        <div>• 1 caracter especial</div>
                      </div>
                    </div>
                    )
                  }
                  {equalPasswordMsgForm
                    && (
                    <div className='mb-3'>
                      <div className='alert alert-danger text-center'>Las contraseñas no coinciden</div>
                    </div>
                    )
                  }
                  {error
                    && (
                    <div className='mb-3'>
                      <div className='alert alert-danger text-center'>{error_msg}</div>
                    </div>
                    )
                  }
                  <div className='centrar'>
                    <button type='submit' className='btn btn-primary btn-allergien'>Cambiar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
        : (
          loading 
            ? (
              <div className='container'>
                <div className='alert alert-info text-center'>procesando...</div>
              </div>
            )
            : (
              error
              ? (
                <div className="main-container">
                  <div className="allergien-index-main-container">
                    <div className="allergien-index-sub-container">
                      <div className="allergien-index-logo"></div>
                      <div className="allergien-index-name">APP Allergien</div>
                      <div className="allergien-index-text-1 mt-5">¡Error al cambiar contraseña!</div>
                      <div className="allergien-index-text-2 mt-1">{error_msg}</div>
                      <div className="allergien-index-text-2 mt-1">Contacta con el equipo de soporte (449) 8116534355</div>
                    </div>
                  </div>
                </div>
              )
              : (
                <div className="main-container">
                  <div className="allergien-index-main-container">
                    <div className="allergien-index-sub-container">
                      <div className="allergien-index-logo"></div>
                      <div className="allergien-index-name">APP Allergien</div>
                      <div className="allergien-index-text-1 mt-5">Contraseña establecida correctamente</div>
                      <div className="allergien-index-text-2 mt-1">¡Inicia sesion en tu aplicación!</div>
                    </div>
                  </div>
                </div>
              )
            )
        )
      }
    </>
  )
}
