import React from 'react'
import { useLoaderData } from 'react-router-dom';
import { useTokenFetch } from '../hooks/useTokenFetch';

export async function loader({ params }) {
  return params.token;
}

export const EmailConfirmation = () => {

  const token = useLoaderData();

  const { loading, data, error, error_msg } = useTokenFetch('/usuario/email-verification', token, 'POST');

  return (
    <>
      {loading 
        ? (
          <div className='container'>
            <div className='alert alert-info text-center'>verifying...</div>
          </div>
        )
        : (
          error
          ? (
            <div className="main-container">
              <div className="allergien-index-main-container">
                <div className="allergien-index-sub-container">
                  <div className="allergien-index-logo"></div>
                  <div className="allergien-index-name">APP Allergien</div>
                  <div className="allergien-index-text-1 mt-5">Correo no verificado!</div>
                  <div className="allergien-index-text-2 mt-1">{error_msg}</div>
                </div>
              </div>
            </div>
          )
          : (
            <div className="main-container">
              <div className="allergien-index-main-container">
                <div className="allergien-index-sub-container">
                  <div className="allergien-index-logo"></div>
                  <div className="allergien-index-name">APP Allergien</div>
                  <div className="allergien-index-text-1 mt-5">Correo verificado</div>
                  <div className="allergien-index-text-2 mt-1">¡Bienvenido a Allergien, inicia sesion en tu aplicación!</div>
                </div>
              </div>
            </div>
          )
        )
      }
    </>
  )
}
