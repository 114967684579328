import { useState, useEffect, useMemo } from "react";

const baseUrl = process.env.REACT_APP_API_URL;

export const useResetPasswordFetch = (endpoint = '', token = '', method = 'GET', body = {}, exe_flag = true) => {
    
    console.log(JSON.stringify(body));
    const url = `${baseUrl}${endpoint}`;
    
    const [state, setState] = useState({loading: true, data: null, error: false, error_msg: null});

    useEffect(() => {

        //Validacion de existencia de url
        if(exe_flag){

            setState({
                loading:true,
                data: null,
                error: false,
                error_msg: null
            });
    
            var requestOptions = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'x-reset-password-token': token
                },
                body: (['POST', 'PUT'].includes(method)) ? JSON.stringify( body ) : undefined,
                redirect: 'follow'
            };
              
            fetch(url, requestOptions)
                .then(resp => resp.json())
                .then(data => {
                    //Determinación de estado de request
                    if(data.status){
                        //Asignación de estado con la respuesta
                        setState({
                            loading:false,
                            data,
                            error: false,
                            error_msg: ''
                        });
                    }else{
                        //Asignación de estado con la respuesta
                        setState({
                            loading:false,
                            data,
                            error: true,
                            error_msg: data.msg
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setState({
                        data: null,
                        loading: false,
                        error: true,
                        error_msg: 'Error al procesar solicitud'
                    });
                });

        }


    }, [url, endpoint, token, method, exe_flag]);

    return {
        data: state.data,
        loading: state.loading,
        error: state.error,
        error_msg: state.error_msg
    };


}